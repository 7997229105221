export const environment = {
    production: false,
    appVersion: '',
    envName: 'preprod',
    chatEndpoint: '',
    participantServiceEndpoint: 'https://participant.connect.ca-central-1.amazonaws.com',
    updateAttributes : 'https://iy2ywgf7i9.execute-api.ca-central-1.amazonaws.com/preprod/updateContactAttributes',
    validateToken : 'https://iy2ywgf7i9.execute-api.ca-central-1.amazonaws.com/preprod/validateToken',
    ccpUrl : 'https://ac-connect-ae-ca-central-1-preprod.my.connect.aws/ccp-v2',
    apiKey: 'p5LzZAdHnB6liR0nmvpvl2iWayIuDAqq52lV4VCd',
    translation: 'https://c1c7an2n0k-vpce-0c9baca6164fa1686.execute-api.ca-central-1.amazonaws.com/ac-preprod/'
};
